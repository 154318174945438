import PriceSheetPDF from "../../PriceSheetPDF";

function Customization({setStep, prices, setShopDetails, shopDetails}) {
  return (
    <div className="price-wrapper">
      <div className="price-input-wrapper">
        <h1>Logo du magasin</h1>
        <div className="price-input-block">
          <div className="form-group">
            <p>Pour un résultat optimal, le logo doit faire 760 x 175 pixels</p>
            <input type="file" id="shop-logo" onChange={(e) => {
              if(e.target.files.length === 0) {
                return;
              }
              const fileType = e.target.files[0].type
              var reader = new FileReader();
              reader.readAsDataURL(e.target.files[0]);
              reader.onload = function () {
                const image = new Image();
                image.src = reader.result;
                image.onload = function() {
                  const width = image.width;
                  const height = image.height;
                  setShopDetails({...shopDetails, logo: {
                    buffer: reader.result,
                    type: fileType,
                    width: width,
                    height: height
                  }});
                };
              };
              reader.onerror = function (error) {
                console.log('Error: ', error);
              };
            }}/>
          </div>
          <div className="form-group">
            <p>Nom du magasin</p>
            <input type="text" value={shopDetails?.name} onChange={(e) => {
              setShopDetails({...shopDetails, name: e.target.value});
            }}/>
          </div>
        </div>
      </div>
      <div className="price-input-preview">
        <PriceSheetPDF prices={prices} shopDetails={shopDetails} />
      </div>
    </div>
  );
}

export default Customization;
