function Splash({setStep}) {
  return (
    <>
      <div className="logo"><img alt={''} src={'assets/images/logo.png'} /></div>
      <div className="slider-image">
        <img alt={''} src={'assets/images/splash.jpg'} />
      </div>
      <div className="slider-intro">
        <div className="slider-intro-content">
          <h1 className="slider-intro-title">Bienvenue sur le module de création de votre tapis de vente<br/> secondes paires Nikon</h1>
          <p className="slider-intro-text">Personnalisez votre offre seconde paire Switch Collection en indiquant vos prix de vente consommateurs.
          <br/>Le tapis peut être téléchargé pour en faire une impression ou enregistré sur votre ordinateur pour une utilisation digitale.</p>
        </div>
        <button className="btn" onClick={() => setStep(1)}>Commencer <img src="/assets/images/chevron-right-circle.svg" width="25" /></button>
      </div>
    </>
  );
}

export default Splash;
